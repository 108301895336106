<template>
  <div>
    <b-card>
      <div>
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="openAddPage"
                  class="ml-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-right">新增</span>
              </b-button>

            </div>
          </b-col>
        </b-row>
        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card
              v-show="advanced_search_modal">
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="setting_id:"
                    label-for="setting_id"
                    label-size="sm"
                >
                  <b-form-input
                      id="setting_id"
                      v-model="condition.setting_id"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="功能名称:"
                    label-for="function_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="function_name"
                      v-model="condition.function_name"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="执行分类:"
                    label-for="code_type"
                    label-size="sm"
                >
                  <v-select
                      id="code_type"
                      :options="getCodeOptions('sbcmq_code_type')"
                      :clearable="true"
                      v-model = "condition.code_type"
                      class="select-size-sm"
                      placeholder="选择分类"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="是否需要处理返回值:"
                    label-for="is_return"
                    label-size="sm"
                >
                  <v-select
                      id="is_return"
                      :options="getCodeOptions('yesno')"
                      :clearable="true"
                      v-model = "condition.is_return"
                      class="select-size-sm"
                      placeholder="选择是否需要处理返回值"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </div>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="invoice_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <!-- Columns -->
        <template #cell(setting_id)="data">
          {{data.item.setting_id}}
        </template>
        <template #cell(project_id)="data">
          {{getCodeLabel('sbcmq_project_id', data.item.project_id)}}
        </template>
        <template #cell(function_name)="data">
          {{data.item.function_name}}
        </template>
        <template #cell(code_type)="data">
          {{getCodeLabel('sbcmq_code_type', data.item.code_type)}}
        </template>
        <template #cell(http_url)="data">
          <div :id="`url-${data.item.setting_id}`">
            <span v-if="data.item.http_url">
              {{ data.item.http_url.substring(0, 3) }}
              <span v-if="data.item.http_url.length>3">...</span>
            </span>
          </div>
          <b-tooltip :target="`url-${data.item.setting_id}`" placement="top">
            {{ data.item.http_url }}
          </b-tooltip>
        </template>
        <template #cell(http_method)="data">
          {{data.item.http_method==1?'Post':(data.item.http_method==2?'Get':'')}}
        </template>
        <template #cell(http_header)="data">
          <div :id="`header-${data.item.setting_id}`">
            <span v-if="data.item.http_header">
              {{ data.item.http_header.substring(0, 3) }}
              <span v-if="data.item.http_header.length>3">...</span>
            </span>
          </div>
          <b-tooltip :target="`header-${data.item.setting_id}`" placement="top">
            {{ data.item.http_header }}
          </b-tooltip>
        </template>
        <template #cell(mq_classname)="data">
          {{getCodeLabel('sbcmq_classname', data.item.mq_classname)}}
        </template>
        <template #cell(mq_topic)="data">
          {{data.item.mq_topic}}
        </template>
        <template #cell(mq_tag)="data">
          {{data.item.mq_tag}}
        </template>
        <template #cell(is_return)="data">
          {{getCodeLabel('yesno', data.item.is_return)}}
        </template>
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="openEditPage(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteItem(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

          </b-dropdown>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
      <!--/ form -->
      <b-modal
          id="loadingModal"
          centered
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
          ref="loadingModal"
      >
        <p class="my-4">正在处理，请勿关闭浏览器!</p>
      </b-modal>
      <b-modal
          id="modal-add"
          ok-title="确认"
          @ok="saveInfo"
          cancel-title="取消"
          centered
          size="lg"
          :title="'新增自动执行程序接口配置'"
          ref="addModal"
      >
        <auto-execute-setting-edit
            ref="addReceive" :setting_id="setting_id">
        </auto-execute-setting-edit>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { toDate,avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions,isEmpty } from '@core/utils/filter'
import autoExecuteSettingStore from "@/views/apps/autoexecutesetting/autoExecuteSettingStore";
import autoExecuteSettingEdit from "@/views/apps/autoexecutesetting/AutoExecuteSettingEdit.vue";
import autoExecuteSetting from './autoExecuteSetting.js'
import axios from "@/libs/axios";
import XyInputButton from "@/views/components/xy/XyInputButton";
import {second} from "@core/utils/dayjs/utils";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
    autoExecuteSettingEdit,
  },
  data() {
    return {
      advanced_search_modal: false,
      setting_id:0,
      attachmentsStr: "",
      reach_date:'',
      reach_amount:0,
      attachments_reach:'',
      receive_date:'',
      receive_amount:0,
      attachments_receive:'',
    }
  },
  methods: {
    searchByCondition(){
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('autoExecuteSettingList/updateCondition', this.state.condition)
      this.refetchData()
    },
    openAddPage: function () {
      this.setting_id = 0;
      this.$refs['addModal'].show();
    },
    openEditPage: function (item) {
      this.setting_id = item.setting_id;
      this.$refs['addModal'].show();
    },
    saveInfo: function (bvModalEvt){
      let saveInfo = this.$refs.addReceive.getSaveInfo();
      if(saveInfo.project_id == undefined || saveInfo.project_id == ''){
        alert("请选择项目ID");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.function_name == undefined || saveInfo.function_name == ''){
        alert("请填写功能名称");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.code_type == undefined || saveInfo.code_type == ''){
        alert("请选择执行分类");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.code_type == 1&&(saveInfo.http_url == undefined || saveInfo.http_url == '')){
        alert("请填写URL地址");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.code_type == 1&&(saveInfo.http_method == undefined || saveInfo.http_method == '')){
        alert("请选择Http数据提交方式");
        bvModalEvt.preventDefault();
        return false;
      }
      /*if(saveInfo.code_type == 1&&(saveInfo.http_header == undefined || saveInfo.http_header == '')){
        alert("请填写http_header");
        bvModalEvt.preventDefault();
        return false;
      }*/
      if(saveInfo.code_type == 2&&(saveInfo.mq_classname == undefined || saveInfo.mq_classname == '')){
        alert("请选择MQ类名");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.code_type == 2&&(saveInfo.mq_topic == undefined || saveInfo.mq_topic == '')){
        alert("请填写MQ topic");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.code_type == 2&&(saveInfo.mq_tag == undefined || saveInfo.mq_tag == '')){
        alert("请填写MQ tag");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.is_return == undefined || saveInfo.is_return == ''){
        alert("请选择是否需要处理返回值");
        bvModalEvt.preventDefault();
        return false;
      }
      if(saveInfo.code_type == 1){
        saveInfo.mq_classname = null
        saveInfo.mq_topic = null
        saveInfo.mq_tag = null
      }else{
        saveInfo.http_url = null
        saveInfo.http_method = null
        saveInfo.http_header = null
      }
      this.$refs['loadingModal'].show()
      store
          .dispatch('autoExecuteSettingList/save', saveInfo)
          .then(res => {
            this.$refs['loadingModal'].hide()
            if (res.data.code === 0) {
              this.refetchData()
            } else {
              alert(res.data.data)
            }
          })
    },
    deleteItem: function (item) {
      this.$refs['loadingModal'].show()
      store
          .dispatch('autoExecuteSettingList/deleteItem', {
            setting_id: item.setting_id,
          })
          .then(res => {
            this.$refs['loadingModal'].hide()
            if (res.data.code === 0) {
              this.refetchData()
            } else {
              alert(res.data.data)
            }
          })
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {
    const toast = useToast()

    const state = reactive({
      condition: {},
    })
    // Register module
    if (!store.hasModule('autoExecuteSettingList')) store.registerModule('autoExecuteSettingList', autoExecuteSettingStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('autoExecuteSettingList')) store.unregisterModule('autoExecuteSettingList')
    })
    onMounted(() => {
      state.condition = store.getters['autoExecuteSettingList/getCondition']
    })

    const advanced_search = function () {
      this.advanced_search_modal = !this.advanced_search_modal;
    }


    const {
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,


    } = autoExecuteSetting(
        {}
    )

    return {
      ...toRefs(state),
      state,
      searchList,
      advanced_search,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      toDate,
      getCodeColor,
      getCodeOptions,
      isEmpty,
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
