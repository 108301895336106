import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function autoExecuteSetting(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'setting_id', label: '配置ID' , sortable: true},
    { key: 'project_id', label: '项目ID' , sortable: true},
    { key: 'function_name', label: '功能名称' , sortable: true},
    { key: 'code_type', label: '执行分类' , sortable: true},
    { key: 'http_url', label: 'URL 地址' , sortable: true},
    { key: 'http_method', label: 'Http数据提交方式' , sortable: true},
    { key: 'http_header', label: 'Http Header' , sortable: true},
    { key: 'mq_classname', label: 'MQ类名' , sortable: true},
    { key: 'mq_topic', label: 'MQ topic' , sortable: true},
    { key: 'mq_tag', label: 'MQ tag' , sortable: true},
    { key: 'is_return', label: '是否需要处理返回值' , sortable: true},
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref("setting_id")
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['autoExecuteSettingList/getCondition']}
    store
        .dispatch('autoExecuteSettingList/search', {
          setting_id: condition.setting_id,
          function_name: condition.function_name,
          code_type: condition.code_type,
          is_return: condition.is_return,
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '自动执行程序接口配置列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,

  }
}
