<template>
  <b-card>
    <b-form id="storeCasgOutEditForm" class="edit-form mt-2">
      <b-row>
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="项目ID:"
              label-for="project_id"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="project_id"
                :options="getCodeOptions('sbcmq_project_id')"
                :clearable="false"
                v-model="autoExecuteSetting.project_id"
                class="select-size-sm"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="功能名称"
              label-for="function_name"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="function_name"
                size="sm"
                v-model="autoExecuteSetting.function_name"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="执行分类:"
              label-for="code_type"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="code_type"
                :options="getCodeOptions('sbcmq_code_type')"
                :clearable="false"
                v-model="autoExecuteSetting.code_type"
                @input="changeCodeType($event)"
                class="select-size-sm"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>

        <b-col md="6" v-show="show_http">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="URL地址"
              label-for="http_url"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="http_url"
                size="sm"
                v-model="autoExecuteSetting.http_url"
            />
          </b-form-group>
        </b-col>

        <b-col md="6" v-show="show_http">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="Http数据提交方式:"
              label-for="http_method"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="http_method"
                :options="http_commit_select"
                :clearable="false"
                v-model="autoExecuteSetting.http_method"
                class="select-size-sm"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>

        <b-col md="6" v-show="show_http">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="Http Header"
              label-for="http_header"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="http_header"
                size="sm"
                v-model="autoExecuteSetting.http_header"
            />
          </b-form-group>
        </b-col>

        <b-col md="6" v-show="show_mq">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="MQ类名:"
              label-for="mq_classname"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="mq_classname"
                :options="getCodeOptions('sbcmq_classname')"
                :clearable="false"
                v-model="autoExecuteSetting.mq_classname"
                class="select-size-sm"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>


        <b-col md="6" v-show="show_mq">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="MQ topic"
              label-for="mq_topic"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="mq_topic"
                size="sm"
                v-model="autoExecuteSetting.mq_topic"
            />
          </b-form-group>
        </b-col>

        <b-col md="6" v-show="show_mq">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="MQ tag"
              label-for="mq_tag"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="mq_tag"
                size="sm"
                v-model="autoExecuteSetting.mq_tag"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="是否需要处理返回值:"
              label-for="is_return"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="is_return"
                :options="getCodeOptions('yesno')"
                :clearable="false"
                v-model="autoExecuteSetting.is_return"
                class="select-size-sm"
                :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

  </b-card>
</template>


<script>

import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {computed, onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
// Notification
import {useToast} from 'vue-toastification/composition'
import {getCodeOptions, getCode,getCodeLabel, getCodeColor, avatarText, isEmpty, toDate} from '@core/utils/filter'
import autoExecuteSettingStore from "@/views/apps/autoexecutesetting/autoExecuteSettingStore";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BModal,
    BFormCheckbox,
    vSelect,
    useToast,
  },
  props:{
    setting_id :{
      type : Number,
      default:0
    },
  },
  data() {
    return {
      autoExecuteSetting: ref({}),
      show_http: true,
      show_mq: false,
      http_commit_select: [
        {label: 'Post', value: '1'},
        {label: 'Get', value: '2'}
      ],
    }
  },
  methods: {
    getSaveInfo(){
      return this.autoExecuteSetting;
    },
    changeCodeType(event){
      if(event == 1){
        this.show_http=true
        this.show_mq=false
      }else{
        this.show_http=false
        this.show_mq=true
      }
    }
  },
  setup(props) {
    // Register module
    if (!store.hasModule('autoExecuteSettingEdit')) store.registerModule('autoExecuteSettingEdit', autoExecuteSettingStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('autoExecuteSettingEdit')) store.unregisterModule('autoExecuteSettingEdit')
    })
    const toast = useToast()

    const edit = function () {
      store.dispatch('autoExecuteSettingEdit/initEditPage', {setting_id:props.setting_id}).then(res => {
        this.autoExecuteSetting = res.data.data
        if(res.data.data.project_id){
          this.autoExecuteSetting.project_id = res.data.data.project_id.toString()
        }
        if(res.data.data.code_type){
          this.autoExecuteSetting.code_type = res.data.data.code_type.toString()
        }
        if(res.data.data.http_method){
          this.autoExecuteSetting.http_method = res.data.data.http_method.toString()
        }
        if(res.data.data.mq_classname){
          this.autoExecuteSetting.mq_classname = res.data.data.mq_classname.toString()
        }
        if(res.data.data.is_return != null){
          this.autoExecuteSetting.is_return = res.data.data.is_return.toString()
        }
      })
    }

    return {
      toast,
      edit,
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      toDate,
      getCodeColor,

    }
  },
  created() {
    this.edit();
  },
}
</script>
